var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('octo-header-button',{attrs:{"title":this.classroom.code,"buttons":_vm.headerButtons},on:{"onSaveStudents":_vm.saveStudents}})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('card',{staticClass:"mr-2 shadow mb-3",attrs:{"body-classes":"standard-card-body","header-classes":"my-0 py-2"}},[_c('template',{slot:"header"},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('h4',{staticClass:"card-title"},[_vm._v(_vm._s(_vm.$t('fields.schedulation'))+":")]),_c('label-theme-component',[_vm._v(_vm._s(_vm.classroom.schedulation))])],1)])],2)],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('card',{staticClass:"mr-2 shadow mb-3",attrs:{"body-classes":"standard-card-body"}},[_c('template',{slot:"header"},[_c('h4',{staticClass:"card-title text-capitalize"},[_vm._v(_vm._s(_vm.$t('didactics.joined_students')))])]),_c('el-table',{attrs:{"width":"100","stripe":"","data":_vm.studentsSelected}},[_c('el-table-column',{attrs:{"fixed":"","align":"left","min-width":200,"label":_vm.$t('datatable.name')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{},[_c('div',{staticClass:"text-truncate small text-uppercase"},[_vm._v(" "+_vm._s(_vm._f("optional")(row,'registry.surname'))+" "+_vm._s(_vm._f("optional")(row,'registry.name'))+" ")]),_c('div',{staticClass:"small text-capitalize"},[_vm._v(" "+_vm._s(_vm.$t('datatable.date_in'))+": "),_c('label-theme-component',[_vm._v(_vm._s(_vm._f("date")(row.date_in)))])],1)])}}])}),_c('el-table-column',{attrs:{"align":"center","width":160},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{},[(row.deal)?_c('badge',{staticClass:"text-uppercase",style:({backgroundColor: _vm.schedulerService.getDealStatusColor(row.deal.status)})},[_vm._v(" "+_vm._s(_vm.$t('deal.status.' + row.deal.status))+" ")]):_vm._e()],1)}}])},[_c('template',{slot:"header"},[_vm._v(_vm._s(_vm.$t('datatable.deal_status')))])],2),_c('el-table-column',{attrs:{"align":"center","width":150},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{},[(row.deal)?_c('badge',{staticClass:"text-uppercase",attrs:{"type":_vm.dealAccountingStatus[row.deal.accounting_status].color}},[_vm._v(" "+_vm._s(_vm.$t('deal.accounting_statuses.' + row.deal.accounting_status))+" ")]):_vm._e()],1)}}])},[_c('template',{slot:"header"},[_vm._v(_vm._s(_vm.$t('datatable.accounting_status')))])],2),_c('el-table-column',{attrs:{"align":"center","width":120,"label":_vm.$t('fields.course')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{},[_c('el-tooltip',{attrs:{"content":_vm._f("optional")(_vm.courses[row.course_id],'title'),"open-delay":300,"placement":"top"}},[_c('div',{staticClass:"text-nowrap small"},[_vm._v(" "+_vm._s(_vm._f("optional")(_vm.courses[row.course_id],'code'))+" ")])])],1)}}])}),_c('el-table-column',{attrs:{"width":150,"align":"center","label":_vm.$t('datatable.first_contact')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{},[_c('div',{staticClass:"text-truncate small"},[_vm._v(" "+_vm._s(_vm._f("date")(row.first_contact))+" ")])])}}])}),_c('el-table-column',{attrs:{"align":"center","width":360,"label":_vm.$t('datatable.schedule_preferences')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{},[_c('student-schedule-preferences',{attrs:{"preferences":row.studentSchedulePreferences}})],1)}}])}),_c('el-table-column',{attrs:{"width":160,"align":"center","label":_vm.$t('datatable.second_contact')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{},[_c('div',{staticClass:"text-truncate small"},[_vm._v(" "+_vm._s(_vm._f("date")(row.second_contact))+" ")])])}}])}),(_vm.showAccountingApproval)?_c('el-table-column',{attrs:{"width":200,"align":"center","label":_vm.$t('common.accounting_approval')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{},[_c('octo-icon',{attrs:{"icon":row.accounting_approval ? 'checked' : 'wrong'}})],1)}}],null,false,1671092116)}):_vm._e(),(_vm.showAccountingApproval)?_c('el-table-column',{attrs:{"width":200,"align":"center","label":_vm.$t('common.enabled_certifications')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{},[_vm._v(" "+_vm._s(_vm.getEnabledCertificationsCodes(row))+" ")])}}],null,false,1645585494)}):_vm._e(),_c('el-table-column',{attrs:{"width":110,"fixed":"right"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
var $index = ref.$index;
return _c('div',{},[_c('base-button',{staticClass:"p-0",attrs:{"icon":"","link":""},on:{"click":function($event){return _vm.showStudent(row)}}},[_c('octo-icon',{attrs:{"icon":"magnifying-glass"}})],1),_c('base-button',{staticClass:"p-0",attrs:{"icon":"","link":""},on:{"click":function($event){return _vm.toggleStudent(row)}}},[_c('octo-icon',{attrs:{"icon":"wrong"}})],1)],1)}}])})],1)],2)],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('card',{staticClass:"mr-2 shadow",attrs:{"body-classes":"standard-card-body"}},[_c('template',{slot:"header"},[_c('h4',{staticClass:"card-title text-capitalize"},[_vm._v(_vm._s(_vm.$t('didactics.available_students')))])]),_c('div',{staticClass:"text-uppercase small d-flex align-items-center justify-content-center mb-3"},[_c('el-switch',{staticClass:"mr-2",model:{value:(_vm.showStudentsWithScheduleOnly),callback:function ($$v) {_vm.showStudentsWithScheduleOnly=$$v},expression:"showStudentsWithScheduleOnly"}}),_c('label-theme-component',[_vm._v(_vm._s(_vm.$t('didactics.show_students_with_schedule_only')))])],1),_c('el-table',{attrs:{"width":"100","stripe":"","data":_vm.studentsAvailable}},[_c('el-table-column',{attrs:{"fixed":"","min-width":200,"align":"left","label":_vm.$t('datatable.name')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{},[_c('div',{staticClass:"text-truncate small text-uppercase"},[_vm._v(" "+_vm._s(_vm._f("optional")(row,'registry.surname'))+" "+_vm._s(_vm._f("optional")(row,'registry.name'))+" ")]),_c('div',{staticClass:"small text-capitalize"},[_vm._v(" "+_vm._s(_vm.$t('datatable.date_in'))+": "),_c('label-theme-component',[_vm._v(_vm._s(_vm._f("date")(row.date_in)))])],1)])}}])}),_c('el-table-column',{attrs:{"align":"center","width":160,"label":_vm.$t('datatable.deal_status')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{},[(row.deal)?_c('badge',{staticClass:"text-uppercase",style:({backgroundColor: _vm.schedulerService.getDealStatusColor(row.deal.status)})},[_vm._v(" "+_vm._s(_vm.$t('deal.status.' + row.deal.status))+" ")]):_vm._e()],1)}}])}),_c('el-table-column',{attrs:{"align":"center","width":150,"label":_vm.$t('datatable.accounting_status')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{},[(row.deal)?_c('badge',{staticClass:"text-uppercase",attrs:{"type":_vm.dealAccountingStatus[row.deal.accounting_status].color}},[_vm._v(" "+_vm._s(_vm.$t('deal.accounting_statuses.' + row.deal.accounting_status))+" ")]):_vm._e()],1)}}])}),_c('el-table-column',{attrs:{"align":"center","width":120,"label":_vm.$t('fields.course')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{},[_c('el-tooltip',{attrs:{"content":_vm._f("optional")(_vm.courses[row.course_id],'title'),"open-delay":300,"placement":"top"}},[_c('div',{staticClass:"text-nowrap small"},[_vm._v(" "+_vm._s(_vm._f("optional")(_vm.courses[row.course_id],'code'))+" ")])])],1)}}])}),_c('el-table-column',{attrs:{"width":150,"align":"center","label":_vm.$t('datatable.first_contact')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{},[_c('div',{staticClass:"text-truncate small"},[_vm._v(" "+_vm._s(_vm._f("date")(row.first_contact))+" ")])])}}])}),_c('el-table-column',{attrs:{"align":"center","width":360,"label":_vm.$t('datatable.schedule_preferences')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{},[_c('student-schedule-preferences',{attrs:{"preferences":row.studentSchedulePreferences}})],1)}}])}),_c('el-table-column',{attrs:{"width":160,"align":"center","label":_vm.$t('datatable.second_contact')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{},[_c('div',{staticClass:"text-truncate small"},[_vm._v(" "+_vm._s(_vm._f("date")(row.second_contact))+" ")])])}}])}),(_vm.showAccountingApproval)?_c('el-table-column',{attrs:{"width":200,"align":"center","label":_vm.$t('common.accounting_approval')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{},[_c('octo-icon',{attrs:{"icon":row.accounting_approval ? 'checked' : 'wrong'}})],1)}}],null,false,1671092116)}):_vm._e(),(_vm.showAccountingApproval)?_c('el-table-column',{attrs:{"width":200,"align":"center","label":_vm.$t('common.enabled_certifications')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{},_vm._l((row.registry.enabled_certifications),function(cert,index){return (row.registry && row.registry.enabled_certifications)?_c('div',{key:("cert-" + index)},[_c('div',{staticClass:"small"},[_vm._v(_vm._s(_vm._f("optional")(_vm.courses[cert.id],'code')))])]):_vm._e()}),0)}}],null,false,4218306719)}):_vm._e(),_c('el-table-column',{attrs:{"width":110,"fixed":"right"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
var $index = ref.$index;
return _c('div',{},[_c('base-button',{staticClass:"p-0",attrs:{"icon":"","link":""},on:{"click":function($event){return _vm.showStudent(row)}}},[_c('octo-icon',{attrs:{"icon":"magnifying-glass"}})],1),_c('base-button',{staticClass:"p-0",attrs:{"icon":"","link":""},on:{"click":function($event){return _vm.toggleStudent(row)}}},[_c('octo-icon',{attrs:{"icon":"add"}})],1)],1)}}])})],1)],2)],1)]),_c('modal',{attrs:{"bodyClasses":"px-2 py-0","centered":"","show":_vm.showModal,"modalClasses":"modal-lg"},on:{"update:show":function($event){_vm.showModal=$event}}},[_c('h5',{staticClass:"modal-title text-uppercase",attrs:{"slot":"header"},slot:"header"},[_vm._v(" "+_vm._s(_vm.$t('didactics.student_data'))+" ")]),_c('classroom-pending-student-data',{key:("student-data-" + _vm.studentKey),attrs:{"student":_vm.currentStudent}}),_c('octo-notes',{key:("octo-notes-" + _vm.studentKey),staticClass:"mt-2",attrs:{"entity":"student","entity-id":_vm.currentStudent.id,"notes":_vm.currentStudent.notes},on:{"onSavedNotes":_vm.updateStudentNotes}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }