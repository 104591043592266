<template>
  <div class="row">
    <div class="col-12">
      <card class="shadow" body-classes="standard-card-body pt-0">
        <template slot="header">
          <div class="d-flex justify-content-between align-items-center">
            <h4 class="card-title text-capitalize">{{ $t('fields.certificates') }}</h4>
            <base-button class="mx-1" size="sm" link icon @click="modalKey++; showModal = true;">
              <octo-icon icon="add"/>
            </base-button>
          </div>
        </template>
        <ul class="list-group list-group-flush">
          <list-group-item-component
            v-for="certificate in selectedCertificates"
            v-bind:key="`course-${certificate.id}`"
          >
            <template slot="label">
              <div class="standard-label text-truncate">
                <label-theme-component>{{ certificate.code + ': ' }}</label-theme-component>
                {{ certificate.title }}
              </div>
            </template>
            <template slot="value">
              <base-button
                link
                icon
                confirm-required
                class="my-0"
                size="sm"
                :confirm-text="$t('confirm.delete_certificate')"
                @click="deleteCertificate(certificate)"
              >
                <octo-icon icon="wrong"/>
              </base-button>
            </template>
          </list-group-item-component>
        </ul>
      </card>
    </div>
    <modal centered bodyClasses="p-1" :show.sync="showModal" modalClasses="modal-lg">
      <h5 slot="header" class="modal-title text-uppercase">{{ $t('common.add_certificates') }}</h5>
      <list-selector-component :list="availableCertificates" ref="listSelectorComponent">
        <template v-slot:default="slotProps">
          <div class="text-uppercase small">
            <label-theme-component>{{ slotProps.item | optional('code') }}:</label-theme-component>
            {{ slotProps.item | optional('title') }}
          </div>
        </template>
      </list-selector-component>
      <template slot="footer">
        <base-button link class="text-capitalize" @click="addCertificates">
          {{ $t('common.add') }}
        </base-button>
      </template>
    </modal>
  </div>
</template>

<script>
import OctoHeaderButton from "@/components/octo-header-buttons/OctoHeaderButton";
import {mapGetters, mapMutations} from "vuex";
import ListGroupItemComponent from "@/components/ListGroupItemComponent";
import LabelThemeComponent from "@/components/LabelThemeComponent";
import Modal from "@/components/Modal";
import ListSelectorComponent from "@/components/ListSelectorComponent";
import OctoIcon from "@/components/octo-icon/OctoIcon";
import {endpoints} from "@/routes/endpoints";

export default {
  name: "ClassroomPendingRecapCertificates",
  components: {
    OctoIcon,
    ListSelectorComponent,
    Modal,
    LabelThemeComponent,
    ListGroupItemComponent,
    OctoHeaderButton,
  },
  data() {
    return {
      labelWidth: 160,
      availableCertificates: [],
      selectedCertificates: [],
      showModal: false,
      modalKey: 0
    }
  },
  computed: {
    ...mapGetters({
      classroom: 'current/classroomPending',
      certificates: 'common/certificates',
      courses: 'common/allCourses',
    }),
  },
  watch: {
    classroom: {
      handler(val) {
        this.selectedCertificates = this.$_.cloneDeep(this.classroom.courses);

        this.availableCertificates = this.$_.filter(this.$_.cloneDeep(this.certificates), certificate => {
          return !this.$_.find(this.classroom.courses, course => course.id === certificate.id);
        });

        this.$refs.listSelectorComponent.itemsSelected = [];
      },
      deep: true
    },
  },
  methods: {
    ...mapMutations({
      setPartialClassroomPending: 'current/setPartialClassroomPending',
    }),

    addCertificates() {
      this.selectedCertificates = this.$_.concat(
        this.selectedCertificates,
        this.$refs.listSelectorComponent.getSelectedItems()
      )

      this.syncCertificates();
    },

    deleteCertificate(certificate) {
      this.selectedCertificates.splice(
        this.$_.findIndex(this.selectedCertificates, selectedCertificate => selectedCertificate.id === certificate.id),
        1
      );

      this.syncCertificates();
    },

    syncCertificates: function () {
      this.$fullLoading.show();

      this.$api.put(
        endpoints.CLASSROOM_SYNC_CERTIFICATES_PENDING.replace('{id}', this.classroom.id),
        {certificateIds: this.$_.map(this.selectedCertificates, certificate => certificate.id)}
      )
        .then((resp) => {
          this.setPartialClassroomPending({key: 'courses', value: resp?.data?.data?.courses});
          this.setPartialClassroomPending({key: 'availableStudents', value: resp?.data?.meta?.availableStudents});
          this.showModal = false;
        })
        .catch((e) => {
          console.log(e);

          this.selectedCertificates = this.$_.cloneDeep(this.classroom.courses);

          this.availableCertificates = this.$_.filter(this.$_.cloneDeep(this.certificates), certificate => {
            return !this.$_.find(this.classroom.courses, course => course.id === certificate.id);
          });

          this.$notify({type: 'danger', message: this.$t('notifications.saved_error')});
        })
        .finally(() => {
          this.$fullLoading.hide();
        })
    },
  }
}
</script>

<style scoped>

</style>
