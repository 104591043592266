<template>
  <div>
    <octo-header-button
      :title="this.classroom.code"
      :buttons="headerButtons"
      @onSaveLessons="saveLessons"
      @onAddLesson="openFormModal({}, -1)"
      @onGenerateCalendar="showGeneratorModal = true; lessonFormKey++;"
    />
    <card body-classes="standard-card-body" class="mr-2 shadow">
      <div slot="header">
        <div class="d-flex align-items-center">
          <h4 class="card-title text-capitalize">{{ $t('fields.lessons') }}</h4>
        </div>
      </div>
      <el-table stripe :data="lessonsLocal">
        <el-table-column align="left" :label="$t('datatable.date')">
          <div slot-scope="{ row }">
            <label-theme-component class=" text-uppercase">{{ row.date | day('short') }}</label-theme-component>
            {{ row.date | date }}
          </div>
        </el-table-column>
        <el-table-column align="center" :label="$t('fields.schedule')">
          <div slot-scope="{ row }">
            {{ row.time_start | time }} - {{ row.time_end | time }}
          </div>
        </el-table-column>
        <el-table-column align="center" :label="$t('datatable.code')">
          <div slot-scope="{ row }">
            <div class="small">{{ row.code }}</div>
          </div>
        </el-table-column>
        <el-table-column align="center" width="300" :label="$t('common.location')">
          <div slot-scope="{ row, $index }">
            <base-input class="mb-0">
              <el-select
                class="select-default text-uppercase"
                filterable
                clearable
                value-key="id"
                :placeholder="$t('common.virtual')"
                v-model="row.location_id"
              >
                <el-option
                  class="select-default text-uppercase"
                  v-for="(location, index) in locations"
                  :value="location.id"
                  :label="location.alias"
                  :key="`location-${index}`"
                />
              </el-select>
            </base-input>
          </div>
        </el-table-column>
        <el-table-column align="right" width="120">
          <div slot-scope="{ row, $index }" class="d-flex justify-content-end align-items-center">
            <base-button
              @click="openFormModal(row, $index)"
              size="sm"
              icon
              link>
              <octo-icon icon="edit"/>
            </base-button>
            <base-button
              confirm-required
              :confirm-text="$t('confirm.delete_lesson_text')"
              @click="lessonsLocal.splice($index, 1)"
              type="danger"
              icon
              link>
              <octo-icon icon="wrong"/>
            </base-button>
          </div>
        </el-table-column>
      </el-table>

      <modal :show.sync="showFormModal" bodyClasses="px-1" centered>
        <template slot="header">
          <h5 class="modal-title text-uppercase">
            {{ (selectedLessonIndex === -1) ? $t('didactics.add_lesson') : $t('didactics.edit_lesson') }}
          </h5>
        </template>
        <lesson-form :lesson="selectedLesson" :key="lessonFormKey" ref="lessonForm"/>
        <template slot="footer">
          <base-button link class="text-capitalize" @click="saveLesson">
            {{ (selectedLessonIndex === -1) ? $t('common.add') : $t('common.update') }}
          </base-button>
        </template>
      </modal>

      <modal bodyClasses="px-1" :show.sync="showGeneratorModal" centered>
        <template slot="header">
          <h5 class="modal-title text-uppercase">
            {{ $t('didactics.generate_calendar') }}
          </h5>
        </template>
        <lesson-generator :key="lessonFormKey" ref="lessonGenerator" :classroom="classroom"/>
        <template slot="footer">
          <base-button confirm-required
                       class="text-uppercase"
                       link
                       :confirm-title="$t('confirm.generate_lessons_title')"
                       :confirm-text="$t('confirm.generate_lessons_text')"
                       @click="overwriteLessons"
          >
            {{ $t('common.update') }}
          </base-button>

        </template>
      </modal>
    </card>
  </div>
</template>

<script>
import BaseTable from "@/components/BaseTable";
import Modal from "@/components/Modal";
import OctoIcon from "@/components/octo-icon/OctoIcon";
import LessonForm from "@/pages/Didactics/forms/LessonForm";
import Lesson from "@/models/lesson";
import LessonGenerator from "@/pages/Didactics/components/LessonGenerator";
import LabelThemeComponent from "@/components/LabelThemeComponent";
import OctoHeaderButton from "@/components/octo-header-buttons/OctoHeaderButton";
import {endpoints} from "@/routes/endpoints";
import {mapGetters, mapMutations} from "vuex";
import {Table, TableColumn, Select, Option} from "element-ui";

export default {
  name: "ClassroomPendingLessons",
  components: {
    OctoHeaderButton,
    LabelThemeComponent,
    LessonGenerator,
    LessonForm,
    OctoIcon,
    Modal,
    BaseTable,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Select.name]: Select,
    [Option.name]: Option,
  },
  data() {
    return {
      showFormModal: false,
      showGeneratorModal: false,
      lessonsLocal: [],
      lessonFormKey: 1,
      selectedLesson: Lesson,
      selectedLessonIndex: null,
      headerButtons: [
        {
          label: 'add_lesson',
          onClick: 'onAddLesson'
        },
        {
          label: 'generate_calendar',
          onClick: 'onGenerateCalendar'
        },
        {
          label: 'save_lessons',
          onClick: 'onSaveLessons'
        },
      ]
    }
  },

  computed: {
    ...mapGetters({
      classroom: 'current/classroomPending',
      locations: 'common/locations'
    }),
  },
  watch: {
    classroom: function (val) {
      this.lessonsLocal = this.$_.sortBy(this.classroom.lessons, 'date');
    },
  },
  methods: {
    ...mapMutations({
      setPartialClassroomPending: 'current/setPartialClassroomPending'
    }),

    openFormModal(lesson, index) {
      if (this.$_.isEmpty(lesson)) {
        const exampleLesson = this.$_.cloneDeep(this.lessonsLocal[0]) || this.$_.cloneDeep(Lesson);

        this.selectedLesson = {
          time_start: exampleLesson.time_start || '',
          time_end: exampleLesson.time_end || '',
        }
      } else {
        this.selectedLesson = this.$_.cloneDeep(lesson);
      }

      this.selectedLessonIndex = index;
      this.lessonFormKey++;
      this.showFormModal = true;
    },

    saveLesson: async function () {
      const lesson = await this.$refs.lessonForm.validate();

      if (this.selectedLessonIndex < 0) {
        this.lessonsLocal.push(lesson);
      } else {
        this.$set(this.lessonsLocal, this.selectedLessonIndex, lesson);
      }

      this.lessonsLocal = this.$_.sortBy(this.lessonsLocal, lesson => {
        return this.$moment(lesson.date)
      });

      this.showFormModal = false;
    },

    overwriteLessons: async function () {
      const lessons = await this.$refs.lessonGenerator.validate();

      this.lessonsLocal = this.$_.sortBy(lessons, lesson => {
        return this.$moment(lesson.date)
      });

      this.showGeneratorModal = false;
    },

    saveLessons() {
      this.$fullLoading.show();

      this.$api.put(
        endpoints.CLASSROOM_SYNC_LESSONS_PENDING.replace('{id}', this.classroom.id),
        {lessons: this.lessonsLocal}
      )
        .then((resp) => {
          this.setPartialClassroomPending({key: 'lessons', value: resp?.data?.data?.lessons});
          this.setPartialClassroomPending({key: 'status', value: resp?.data?.data?.status});
          this.$notify({type: 'success', message: this.$t('notifications.saved_success')});
        })
        .catch(() => {
          this.$notify({type: 'danger', message: this.$t('notifications.saved_error')});
        })
        .finally(() => {
          this.$fullLoading.hide();
        });
    }
  },
}
</script>

<style scoped>

</style>
