<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <card class="shadow" body-classes="standard-card-body pt-0">
          <template slot="header">
            <h4 class="card-title text-capitalize">{{ $t('didactics.students') }}</h4>
          </template>
          <ul class="list-group list-group-flush">
            <li class="list-group-item text-center" v-if="!classroom.students.length">
              {{ $t('common.empty_list') }}
            </li>
            <list-group-item-component v-for="(student, index) in classroom.students" v-bind:key="`student-${index}`">
              <template slot="label">
                <div class="standard-label text-truncate">
                  {{ student | optional('registry.surname') }} {{ student | optional('registry.name') }}
                </div>
              </template>
              <template slot="value">
                <base-button
                  link
                  icon
                  size="sm"
                  class="ml-auto text-capitalize my-0"
                  @click="$router.push({name: 'students.waiting.show', params: {id: student.id}})"
                >
                  <octo-icon icon="magnifying-glass"/>
                </base-button>
              </template>
            </list-group-item-component>
          </ul>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import OctoHeaderButton from "@/components/octo-header-buttons/OctoHeaderButton";
import {mapGetters} from "vuex";
import ClassroomPending from "@/store/current/resources/classroomPending";
import ListGroupItemComponent from "@/components/ListGroupItemComponent";
import LabelThemeComponent from "@/components/LabelThemeComponent";
import OctoIcon from "@/components/octo-icon/OctoIcon";

export default {
  name: "ClassroomPendingRecapStudents",
  components: {
    OctoIcon,
    LabelThemeComponent,
    ListGroupItemComponent,
    OctoHeaderButton,
  },
  data() {
    return {
      labelWidth: 160,
      localClassroom: this.$_.cloneDeep(ClassroomPending),
    }
  },
  computed: {
    ...mapGetters({
      classroom: 'current/classroomPending',
    }),
  }
}
</script>

<style scoped>

</style>
