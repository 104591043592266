<template>
  <div>
    <octo-header-button
      :title="this.classroom.code"
      :buttons="headerButtons"
      @onSaveTutors="saveTutors"
    />
    <card class="shadow" body-classes="standard-card-body">
      <div slot="header">
        <h4 class="card-title text-capitalize">{{ $t('fields.tutors') }}</h4>
      </div>
      <list-selector-component :number-items-per-page="18" :list="tutorsLocal" ref="tutorSelector">
        <template v-slot:default="slotProps">
          <div>
            {{ users[slotProps.item.id] | optional('lastname') }}
            {{ users[slotProps.item.id] | optional('firstname') }}
          </div>
        </template>
      </list-selector-component>
      <no-data-component :label="$t('didactics.no_tutors_available')" v-if="!classroom.availableTutors"/>
    </card>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import ListGroupItemComponent from "@/components/ListGroupItemComponent";
import ListSelectorComponent from "@/components/ListSelectorComponent";
import NoDataComponent from "@/components/NoDataComponent";
import OctoHeaderButton from "@/components/octo-header-buttons/OctoHeaderButton";
import {endpoints} from "@/routes/endpoints";

export default {
  name: "AvailableTutorList",
  components: {
    OctoHeaderButton,
    NoDataComponent,
    ListSelectorComponent,
    ListGroupItemComponent
  },
  data() {
    return {
      tutorsLocal: [],
      headerButtons: [
        {
          label: 'save_tutors',
          onClick: 'onSaveTutors'
        },
      ]
    }
  },
  computed: {
    ...mapGetters({
      classroom: 'current/classroomPending',
      users: 'common/users',
    }),
  },
  watch: {
    classroom: function (val) {
      this.$_.each(this.$_.cloneDeep(this.classroom.availableTutors), (item) => {
        item.selected = !!this.$_.find(this.classroom.tutors, tutor => item.id === tutor.id);
        this.tutorsLocal.push(item);
      });
    },
  },
  methods: {
    ...mapMutations({
      setClassroomPendingTutors: 'current/setClassroomPendingTutors'
    }),

    saveTutors() {
      this.$fullLoading.show();

      this.$api.put(
        endpoints.CLASSROOM_SYNC_TUTORS.replace('{id}', this.classroom.id),
        {tutorIds: this.$_.map(this.$refs.tutorSelector.getSelectedItems(), tutor => tutor.id)}
      )
        .then((resp) => {
          this.setClassroomPendingTutors(resp?.data?.data?.tutors);
          this.$notify({type: 'success', message: this.$t('notifications.saved_success')});
        })
        .catch(() => {
          this.$notify({type: 'danger', message: this.$t('notifications.saved_error')});
        })
        .finally(() => {
          this.$fullLoading.hide();
        });
    }
  }
}
</script>

<style scoped>

</style>
