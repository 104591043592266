<template>
  <div class="row">
    <div class="col-12">
      <card class="shadow" body-classes="standard-card-body pt-0">
        <template slot="header">
          <h4 class="card-title text-capitalize">{{ $t('common.courses') }}</h4>
        </template>
        <div class="row">
          <div class="col-12 col-md-6">
            <ul class="list-group list-group-flush">
              <list-group-item-component v-for="(course, index) in coursesChunks[0]" v-bind:key="`course-1-${index}`">
                <template slot="label">
                  <div class="standard-label text-truncate">
                    <label-theme-component>{{ course.code + ': ' }}</label-theme-component>
                    {{ course.title }}
                  </div>
                </template>
              </list-group-item-component>
            </ul>
          </div>
          <div class="col-12 col-md-6">
            <ul class="list-group list-group-flush">
              <list-group-item-component v-for="(course, index) in coursesChunks[1]" v-bind:key="`course-2-${index}`">
                <template slot="label">
                  <div class="standard-label text-truncate">
                    <label-theme-component>{{ course.code + ': ' }}</label-theme-component>
                    {{ course.title }}
                  </div>
                </template>
              </list-group-item-component>
            </ul>
          </div>
        </div>
      </card>
    </div>
  </div>
</template>

<script>
import OctoHeaderButton from "@/components/octo-header-buttons/OctoHeaderButton";
import {mapGetters} from "vuex";
import ClassroomPending from "@/store/current/resources/classroomPending";
import ListGroupItemComponent from "@/components/ListGroupItemComponent";
import LabelThemeComponent from "@/components/LabelThemeComponent";

export default {
  name: "ClassroomPendingRecapCourses",
  components: {
    LabelThemeComponent,
    ListGroupItemComponent,
    OctoHeaderButton,
  },
  data() {
    return {
      labelWidth: 160,
      localClassroom: this.$_.cloneDeep(ClassroomPending),
    }
  },
  computed: {
    ...mapGetters({
      classroom: 'current/classroomPending',
    }),

    coursesChunks() {
      return this.$_.chunk(this.classroom.courses, this.$_.ceil(this.classroom.courses.length / 2));
    }
  }
}
</script>

<style scoped>

</style>
