<template>
  <div class="row">
    <div class="col-12 col-md-6">
      <ul class="list-group list-group-flush">
        <list-group-item-component :label="$t('datatable.name')">
          <template slot="value">
            <label-theme-component>
              {{ student |optional('registry.surname') }}
              {{ student |optional('registry.name') }}
            </label-theme-component>
          </template>
        </list-group-item-component>
        <list-group-item-component
          :label="$t('fields.course')"
          :value="courses[student.course_id] | optional('code')"
        />
        <list-group-item-component
          :label="$t('common.enabled_certifications')"
          :value="getEnabledCertificationsCodes(student)"
        />
      </ul>
    </div>
    <div class="col-12 col-md-6">
      <ul class="list-group list-group-flush">
        <list-group-item-component
          :label="$t('datatable.first_contact')"
          :value="student.first_contact | date"
        />
        <list-group-item-component
          :label="$t('datatable.second_contact')"
          :value="student.second_contact | date"
        />
        <list-group-item-component :label="$t('common.accounting_approval')">
          <template slot="value">
            <octo-icon :icon="student.accounting_approval ? 'checked' : 'wrong'"/>
          </template>
        </list-group-item-component>
      </ul>
    </div>
  </div>
</template>

<script>
import OctoIcon from "@/components/octo-icon/OctoIcon";
import ListGroupItemComponent from "@/components/ListGroupItemComponent";
import {mapGetters} from "vuex";
import LabelThemeComponent from "@/components/LabelThemeComponent";
import Student from "@/models/student";
import OctoNotes from "@/components/OctoNotes";

export default {
  name: "ClassroomPendingStudentData",
  components: {
    OctoNotes,
    LabelThemeComponent,
    ListGroupItemComponent,
    OctoIcon,
  },
  props: {
    student: {
      type: Object,
      default: () => _.cloneDeep(Student)
    }
  },
  computed: {
    ...mapGetters({
      courses: 'common/allCourses'
    }),
  },
  methods: {
    getEnabledCertificationsCodes(student) {
      return this.$_.map(student?.registry?.enabled_certifications, item => this.courses[item.id].code).join(', ');
    }
  }
}
</script>

<style scoped>

</style>
