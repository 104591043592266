<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <tabs centered square>
          <tab-pane :id="1">
            <template slot="label">
              <div class="d-flex align-items-center">
                <div class="text-uppercase m-3">{{ $t('common.summary') }}</div>
              </div>
            </template>
            <classroom-pending-recap class="mb-3"/>
            <classroom-pending-recap-students/>
            <div class="container-fluid">
              <classroom-pending-recap-certificates v-if="classroom.type === classroomTypes.certificate.value"/>
              <classroom-pending-recap-courses v-else/>
              <div class="row">
                <div class="col-12">
                  <octo-notes
                    :key="`octo-notes-${classroomKey}`"
                    entity="classroom"
                    :entity-id="classroom.id"
                    :notes="classroom.notes"
                  />
                </div>
              </div>
            </div>
          </tab-pane>
          <tab-pane
            :id="2"
            v-if="classroom.type === classroomTypes.course.value
            || classroom.type === classroomTypes.workshop.value">
            <template slot="label">
              <div class="d-flex align-items-center">
                <div class="text-uppercase m-3">{{ $t('fields.lessons') }}</div>
              </div>
            </template>
            <classroom-pending-lessons />
          </tab-pane>
          <tab-pane :id="3" v-if="classroom.type === classroomTypes.certificate.value">
            <template slot="label">
              <div class="d-flex align-items-center">
                <div class="text-uppercase m-3">{{ $t('fields.location') }}</div>
              </div>
            </template>
            <classroom-pending-certification-data/>
          </tab-pane>
          <tab-pane :id="4">
            <template slot="label">
              <div class="d-flex align-items-center">
                <div class="text-uppercase m-3">{{ $t('didactics.students') }}</div>
              </div>
            </template>
            <classroom-pending-students/>
          </tab-pane>
          <tab-pane
            :id="5"
            v-if="classroom.type === classroomTypes.course.value
            || classroom.type === classroomTypes.workshop.value">
            <template slot="label">
              <div class="d-flex align-items-center">
                <div class="text-uppercase m-3">{{ $t('fields.tutors') }}</div>
              </div>
            </template>
            <available-tutor-list/>
          </tab-pane>
        </tabs>
      </div>
    </div>
  </div>
</template>

<script>
import {endpoints} from "@/routes/endpoints";
import {TabPane, Tabs} from '@/components';
import OctoHeaderButton from "@/components/octo-header-buttons/OctoHeaderButton";
import ClassroomPendingLessons from "./components/ClassroomPendingLessons";
import {mapMutations} from "vuex";
import ClassroomPendingStudents from "./components/ClassroomPendingStudents";
import AvailableTutorList from "@/pages/Didactics/components/AvailableTutorList";
import ClassroomPending from "@/store/current/resources/classroomPending";
import ClassroomPendingRecap from "@/pages/Didactics/components/ClassroomPendingRecap";
import ClassroomTypes from "@/pages/Didactics/resources/classroomTypes";
import ClassroomPendingCertificationData from "@/pages/Didactics/components/ClassroomPendingCertificationData";
import OctoNotes from "@/components/OctoNotes";
import ClassroomPendingRecapCertificates from "./components/ClassroomPendingRecapCertificates";
import ClassroomPendingRecapCourses from "./components/ClassroomPendingRecapCourses";
import ShowClassroomPendingPageHelper from "@/components/Helpers/ShowClassroomPendingPageHelper";
import ClassroomPendingRecapStudents from "./components/ClassroomPendingRecapStudents";

export default {
  name: "ShowClassroomPendingPage",
  components: {
    ClassroomPendingRecapStudents,
    ClassroomPendingRecapCourses,
    ClassroomPendingRecapCertificates,
    OctoNotes,
    ClassroomPendingCertificationData,
    ClassroomPendingRecap,
    AvailableTutorList,
    ClassroomPendingStudents,
    ClassroomPendingLessons,
    OctoHeaderButton,
    Tabs,
    TabPane,
  },
  data() {
    return {
      endpoints: endpoints,
      classroom: this.$_.cloneDeep(ClassroomPending),
      classroomTypes: ClassroomTypes,
      classroomKey: 0,
      helperComponent: ShowClassroomPendingPageHelper
    }
  },
  beforeMount() {
    this.$fullLoading.show();
    this.classroom = this.$_.cloneDeep(ClassroomPending);
    this.setClassroomPending(this.classroom);
    this.$api.get(endpoints.CLASSROOM_SHOW_WAITING.replace('{id}', this.$route.params.id))
      .then((resp) => {
        this.classroom = {...this.classroom, ...resp?.data?.data}
        this.classroom.availableStudents = resp?.data?.meta?.availableStudents || [];
        this.classroom.availableTutors = resp?.data?.meta?.availableTutors || [];
        this.classroom.locations = resp?.data?.meta?.locations || [];
        this.classroomKey++;
          this.$nextTick(() => {
            this.setClassroomPending(this.classroom);
          });
      })
      .catch((e) => {
        console.log(e);
        this.$notify({type: 'danger', message: this.$t('notifications.loading_error')});
        // this.$router.push({name: 'didactics.classrooms.pending.index'})
      })
      .finally(() => {
        this.$fullLoading.hide();
      })
  },
  computed: {},
  methods: {
    ...mapMutations({
      setClassroomPending: 'current/setClassroomPending',
      setHelper: 'common/setHelper',
    }),
  },
  mounted() {
    this.setHelper(this.helperComponent);
  },
  beforeDestroy() {
    this.setHelper();
  }
}
</script>

<style scoped>

</style>
