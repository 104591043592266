<template>
  <div>
    <p class="text-uppercase mt-2">{{ $t('common.summary') }}</p>
    <div class="ml-2 text-muted">
      Riepilogo della situazione generale dell'aula. Sono presenti i dati dell'aula, l'elenco dei corsi/certificazioni
      e la gestione delle note.
    </div>
    <collapse :multiple-active="false">
      <collapse-item :title="$t('common.save')">
        <div>
          Salva dati generali dell'aula.
        </div>
      </collapse-item>
      <collapse-item :title="$t('common.delete')">
        <div>
          Cancella l'aula e le lezioni. Gli studenti iscritti diventano {{ $t('didactics.student_status.pending') }}.
        </div>
      </collapse-item>
      <collapse-item :title="$t('common.ready_to_go')" v-if="showReadyToGo">
        <div>
          Imposta l'aula come {{ $t('didactics.classroom_status.ready_to_go') }}.
        </div>
      </collapse-item>
      <collapse-item :title="$t('common.not_ready_to_go')" v-if="isReadyToGo">
        <div>
          Ripristina lo stato precedente dell'aula.
        </div>
      </collapse-item>
      <collapse-item :title="$t('common.activate_classroom')" v-if="isReadyToGo">
        <div>
          Imposta l'aula come {{ $t('didactics.classroom_status.on_going') }} e gli studenti diventano frequentanti.
          L'utente viene reindirizzato nell'archivio delle aule per realizzare altre azioni sull'aula corrente.
        </div>
      </collapse-item>
    </collapse>
    <p class="text-uppercase mt-2">{{ $t('fields.lessons') }}</p>
    <div class="ml-2 text-muted">
      Elenco delle lezione dell'aula corrente. Ogni lezione può essere aggiornata o cancellata.
    </div>
    <collapse :multiple-active="false">
      <collapse-item :title="$t('common.add_lesson')">
        <div>
          Apri modale per aggiungere una nuova lezione.
        </div>
      </collapse-item>
      <collapse-item :title="$t('common.generate_calendar')">
        <div>
          Apri modale per la generazione del calendario dell'aula.
        </div>
      </collapse-item>
      <collapse-item :title="$t('common.save_lessons')">
        <div>
          Salva le lezioni dell'elenco.
        </div>
      </collapse-item>
    </collapse>
    <p class="text-uppercase mt-2">{{ $t('didactics.students') }}</p>
    <div class="ml-2 text-muted">
      Elenco degli studenti iscritti e disponibili.
    </div>
    <collapse :multiple-active="false">
      <collapse-item :title="$t('common.save_students')">
        <div>
          Salva gli studenti iscritti di quest'aula.
        </div>
      </collapse-item>
    </collapse>
    <p class="text-uppercase mt-2">{{ $t('fields.tutors') }}</p>
    <div class="ml-2 text-muted">
      Elenco dei docenti disponibili e associati all'aula.
    </div>
    <collapse :multiple-active="false">
      <collapse-item :title="$t('common.save_tutors')">
        <div>
          Associa all'aula i docenti selezionati.
        </div>
      </collapse-item>
    </collapse>
  </div>
</template>

<script>
import CollapseItem from "@/components/Collapse/CollapseItem";
import Collapse from "@/components/Collapse/Collapse";
import classroomStatuses from "@/pages/Didactics/resources/classroomStatuses";
import {mapGetters} from "vuex";

export default {
  name: "ShowClassroomPendingPageHelper",
  components: {CollapseItem, Collapse},
  data() {
    return {
      classroomStatuses: classroomStatuses
    }
  },
  computed: {
    ...mapGetters({
      classroom: 'current/classroomPending'
    }),
    showReadyToGo() {
      return this.classroom.status === classroomStatuses.pending.value
        || this.classroom.status === classroomStatuses.scheduled.value;
    },
    isReadyToGo() {
      return this.classroom.status === classroomStatuses.ready_to_go.value;
    }
  }
}
</script>

<style scoped>

</style>
