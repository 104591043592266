var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('octo-header-button',{attrs:{"title":this.classroom.code,"buttons":_vm.headerButtons},on:{"onSaveLessons":_vm.saveLessons,"onAddLesson":function($event){return _vm.openFormModal({}, -1)},"onGenerateCalendar":function($event){_vm.showGeneratorModal = true; _vm.lessonFormKey++;}}}),_c('card',{staticClass:"mr-2 shadow",attrs:{"body-classes":"standard-card-body"}},[_c('div',{attrs:{"slot":"header"},slot:"header"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('h4',{staticClass:"card-title text-capitalize"},[_vm._v(_vm._s(_vm.$t('fields.lessons')))])])]),_c('el-table',{attrs:{"stripe":"","data":_vm.lessonsLocal}},[_c('el-table-column',{attrs:{"align":"left","label":_vm.$t('datatable.date')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{},[_c('label-theme-component',{staticClass:" text-uppercase"},[_vm._v(_vm._s(_vm._f("day")(row.date,'short')))]),_vm._v(" "+_vm._s(_vm._f("date")(row.date))+" ")],1)}}])}),_c('el-table-column',{attrs:{"align":"center","label":_vm.$t('fields.schedule')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{},[_vm._v(" "+_vm._s(_vm._f("time")(row.time_start))+" - "+_vm._s(_vm._f("time")(row.time_end))+" ")])}}])}),_c('el-table-column',{attrs:{"align":"center","label":_vm.$t('datatable.code')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{},[_c('div',{staticClass:"small"},[_vm._v(_vm._s(row.code))])])}}])}),_c('el-table-column',{attrs:{"align":"center","width":"300","label":_vm.$t('common.location')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
var $index = ref.$index;
return _c('div',{},[_c('base-input',{staticClass:"mb-0"},[_c('el-select',{staticClass:"select-default text-uppercase",attrs:{"filterable":"","clearable":"","value-key":"id","placeholder":_vm.$t('common.virtual')},model:{value:(row.location_id),callback:function ($$v) {_vm.$set(row, "location_id", $$v)},expression:"row.location_id"}},_vm._l((_vm.locations),function(location,index){return _c('el-option',{key:("location-" + index),staticClass:"select-default text-uppercase",attrs:{"value":location.id,"label":location.alias}})}),1)],1)],1)}}])}),_c('el-table-column',{attrs:{"align":"right","width":"120"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
var $index = ref.$index;
return _c('div',{staticClass:"d-flex justify-content-end align-items-center"},[_c('base-button',{attrs:{"size":"sm","icon":"","link":""},on:{"click":function($event){return _vm.openFormModal(row, $index)}}},[_c('octo-icon',{attrs:{"icon":"edit"}})],1),_c('base-button',{attrs:{"confirm-required":"","confirm-text":_vm.$t('confirm.delete_lesson_text'),"type":"danger","icon":"","link":""},on:{"click":function($event){return _vm.lessonsLocal.splice($index, 1)}}},[_c('octo-icon',{attrs:{"icon":"wrong"}})],1)],1)}}])})],1),_c('modal',{attrs:{"show":_vm.showFormModal,"bodyClasses":"px-1","centered":""},on:{"update:show":function($event){_vm.showFormModal=$event}}},[_c('template',{slot:"header"},[_c('h5',{staticClass:"modal-title text-uppercase"},[_vm._v(" "+_vm._s((_vm.selectedLessonIndex === -1) ? _vm.$t('didactics.add_lesson') : _vm.$t('didactics.edit_lesson'))+" ")])]),_c('lesson-form',{key:_vm.lessonFormKey,ref:"lessonForm",attrs:{"lesson":_vm.selectedLesson}}),_c('template',{slot:"footer"},[_c('base-button',{staticClass:"text-capitalize",attrs:{"link":""},on:{"click":_vm.saveLesson}},[_vm._v(" "+_vm._s((_vm.selectedLessonIndex === -1) ? _vm.$t('common.add') : _vm.$t('common.update'))+" ")])],1)],2),_c('modal',{attrs:{"bodyClasses":"px-1","show":_vm.showGeneratorModal,"centered":""},on:{"update:show":function($event){_vm.showGeneratorModal=$event}}},[_c('template',{slot:"header"},[_c('h5',{staticClass:"modal-title text-uppercase"},[_vm._v(" "+_vm._s(_vm.$t('didactics.generate_calendar'))+" ")])]),_c('lesson-generator',{key:_vm.lessonFormKey,ref:"lessonGenerator",attrs:{"classroom":_vm.classroom}}),_c('template',{slot:"footer"},[_c('base-button',{staticClass:"text-uppercase",attrs:{"confirm-required":"","link":"","confirm-title":_vm.$t('confirm.generate_lessons_title'),"confirm-text":_vm.$t('confirm.generate_lessons_text')},on:{"click":_vm.overwriteLessons}},[_vm._v(" "+_vm._s(_vm.$t('common.update'))+" ")])],1)],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }