<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <octo-header-button :title="this.classroom.code" :buttons="headerButtons" @onSaveStudents="saveStudents"/>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <card body-classes="standard-card-body" class="mr-2 shadow mb-3" header-classes="my-0 py-2">
          <template slot="header">
            <div class="d-flex justify-content-between">
              <h4 class="card-title">{{ $t('fields.schedulation') }}:</h4>
              <label-theme-component>{{ classroom.schedulation }}</label-theme-component>
            </div>
          </template>
        </card>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <card body-classes="standard-card-body" class="mr-2 shadow mb-3">
          <template slot="header">
            <h4 class="card-title text-capitalize">{{ $t('didactics.joined_students') }}</h4>
          </template>
          <el-table width="100" stripe :data="studentsSelected">
            <el-table-column
              fixed
              align="left"
              :min-width="200"
              :label="$t('datatable.name')"
            >
              <div slot-scope="{ row }">
                <div class="text-truncate small text-uppercase">
                  {{ row |optional('registry.surname') }}
                  {{ row |optional('registry.name') }}
                </div>
                <div class="small text-capitalize">
                  {{ $t('datatable.date_in') }}:
                  <label-theme-component>{{ row.date_in | date }}</label-theme-component>
                </div>
              </div>
            </el-table-column>
            <el-table-column align="center" :width="160">
              <template slot="header">{{ $t('datatable.deal_status') }}</template>
              <div slot-scope="{ row }">
                <badge  v-if="row.deal"
                  :style="{backgroundColor: schedulerService.getDealStatusColor(row.deal.status)}"
                  class="text-uppercase"
                >
                  {{ $t('deal.status.' + row.deal.status) }}
                </badge>
              </div>
            </el-table-column>
            <el-table-column align="center" :width="150">
              <template slot="header">{{ $t('datatable.accounting_status') }}</template>
              <div slot-scope="{ row }">
                <badge  v-if="row.deal"
                  :type="dealAccountingStatus[row.deal.accounting_status].color"
                  class="text-uppercase"
                >
                  {{ $t('deal.accounting_statuses.' + row.deal.accounting_status) }}
                </badge>
              </div>
            </el-table-column>
            <el-table-column
              align="center"
              :width="120"
              :label="$t('fields.course')"
            >
              <div slot-scope="{ row }">
                <el-tooltip
                  :content="courses[row.course_id] | optional('title')"
                  :open-delay="300"
                  placement="top"
                >
                  <div class="text-nowrap small">
                    {{ courses[row.course_id] | optional('code') }}
                  </div>
                </el-tooltip>
              </div>
            </el-table-column>
            <el-table-column
              :width="150"
              align="center"
              :label="$t('datatable.first_contact')"
            >
              <div slot-scope="{ row }">
                <div class="text-truncate small">
                  {{ row.first_contact | date }}
                </div>
              </div>
            </el-table-column>
            <el-table-column
              align="center"
              :width="360"
              :label="$t('datatable.schedule_preferences')"
            >
              <div slot-scope="{ row }">
                <student-schedule-preferences :preferences="row.studentSchedulePreferences"/>
              </div>
            </el-table-column>
            <el-table-column
              :width="160"
              align="center"
              :label="$t('datatable.second_contact')"
            >
              <div slot-scope="{ row }">
                <div class="text-truncate small">
                  {{ row.second_contact | date }}
                </div>
              </div>
            </el-table-column>
            <el-table-column
              :width="200"
              align="center"
              :label="$t('common.accounting_approval')"
              v-if="showAccountingApproval"
            >
              <div slot-scope="{ row }">
                <octo-icon :icon="row.accounting_approval ? 'checked' : 'wrong'"/>
              </div>
            </el-table-column>
            <el-table-column
              :width="200"
              align="center"
              :label="$t('common.enabled_certifications')"
              v-if="showAccountingApproval"
            >
              <div slot-scope="{ row }">
                {{ getEnabledCertificationsCodes(row) }}
              </div>
            </el-table-column>
            <el-table-column :width="110" fixed="right">
              <div slot-scope="{ row, $index }">
                <base-button icon link class="p-0" @click="showStudent(row)">
                  <octo-icon icon="magnifying-glass"/>
                </base-button>
                <base-button icon link class="p-0" @click="toggleStudent(row)">
                  <octo-icon icon="wrong"/>
                </base-button>
              </div>
            </el-table-column>
          </el-table>
        </card>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <card body-classes="standard-card-body" class="mr-2 shadow">
          <template slot="header">
            <h4 class="card-title text-capitalize">{{ $t('didactics.available_students') }}</h4>
          </template>
          <div class="text-uppercase small d-flex align-items-center justify-content-center mb-3">
            <el-switch class="mr-2" v-model="showStudentsWithScheduleOnly"/>
            <label-theme-component>{{ $t('didactics.show_students_with_schedule_only') }}</label-theme-component>
          </div>
          <el-table width="100" stripe :data="studentsAvailable">
            <el-table-column
              fixed
              :min-width="200"
              align="left" :label="$t('datatable.name')"
            >
              <div slot-scope="{ row }">
                <div class="text-truncate small text-uppercase">
                  {{ row |optional('registry.surname') }}
                  {{ row |optional('registry.name') }}
                </div>
                <div class="small text-capitalize">
                  {{ $t('datatable.date_in') }}:
                  <label-theme-component>{{ row.date_in | date }}</label-theme-component>
                </div>
              </div>
            </el-table-column>
            <el-table-column align="center" :width="160" :label="$t('datatable.deal_status')">
              <div slot-scope="{ row }">
                <badge v-if="row.deal"
                  :style="{backgroundColor: schedulerService.getDealStatusColor(row.deal.status)}"
                  class="text-uppercase"
                >
                  {{ $t('deal.status.' + row.deal.status) }}
                </badge>
              </div>
            </el-table-column>
            <el-table-column align="center" :width="150" :label="$t('datatable.accounting_status')">
              <div slot-scope="{ row }">
                <badge v-if="row.deal"
                  :type="dealAccountingStatus[row.deal.accounting_status].color"
                  class="text-uppercase"
                >
                  {{ $t('deal.accounting_statuses.' + row.deal.accounting_status) }}
                </badge>
              </div>
            </el-table-column>
            <el-table-column
              align="center"
              :width="120"
              :label="$t('fields.course')"
            >
              <div slot-scope="{ row }">
                <el-tooltip
                  :content="courses[row.course_id] | optional('title')"
                  :open-delay="300"
                  placement="top"
                >
                  <div class="text-nowrap small">
                    {{ courses[row.course_id] | optional('code') }}
                  </div>
                </el-tooltip>
              </div>
            </el-table-column>
            <el-table-column
              :width="150"
              align="center"
              :label="$t('datatable.first_contact')">
              <div slot-scope="{ row }">
                <div class="text-truncate small">
                  {{ row.first_contact | date }}
                </div>
              </div>
            </el-table-column>
            <el-table-column
              align="center"
              :width="360"
              :label="$t('datatable.schedule_preferences')"
            >
              <div slot-scope="{ row }">
                <student-schedule-preferences :preferences="row.studentSchedulePreferences"/>
              </div>
            </el-table-column>
            <el-table-column
              :width="160"
              align="center"
              :label="$t('datatable.second_contact')"
            >
              <div slot-scope="{ row }">
                <div class="text-truncate small">
                  {{ row.second_contact | date }}
                </div>
              </div>
            </el-table-column>
            <el-table-column
              :width="200"
              align="center"
              :label="$t('common.accounting_approval')"
              v-if="showAccountingApproval"
            >
              <div slot-scope="{ row }">
                <octo-icon :icon="row.accounting_approval ? 'checked' : 'wrong'"/>
              </div>
            </el-table-column>
            <el-table-column
              :width="200"
              align="center"
              :label="$t('common.enabled_certifications')"
              v-if="showAccountingApproval"
            >
              <div slot-scope="{ row }">
                <div v-if="row.registry && row.registry.enabled_certifications"
                     v-for="(cert, index) in  row.registry.enabled_certifications" v-bind:key="`cert-${index}`">
                  <div class="small">{{ courses[cert.id] | optional('code') }}</div>
                </div>

              </div>
            </el-table-column>
            <el-table-column :width="110" fixed="right">
              <div slot-scope="{ row, $index }">
                <base-button icon link class="p-0" @click="showStudent(row)">
                  <octo-icon icon="magnifying-glass"/>
                </base-button>
                <base-button icon link class="p-0" @click="toggleStudent(row)">
                  <octo-icon icon="add"/>
                </base-button>
              </div>
            </el-table-column>
          </el-table>
        </card>
      </div>
    </div>
    <modal bodyClasses="px-2 py-0" centered :show.sync="showModal" modalClasses="modal-lg">
      <h5 slot="header" class="modal-title text-uppercase">
        {{ $t('didactics.student_data') }}
      </h5>
      <classroom-pending-student-data :student="currentStudent" :key="`student-data-${studentKey}`"/>
      <octo-notes
        class="mt-2"
        entity="student"
        :entity-id="currentStudent.id"
        :notes="currentStudent.notes"
        :key="`octo-notes-${studentKey}`"
        @onSavedNotes="updateStudentNotes"
      />
    </modal>
  </div>
</template>

<script>
import {Table, TableColumn, Switch} from "element-ui";
import {endpoints} from "@/routes/endpoints";
import {mapGetters, mapMutations} from "vuex";
import OctoIcon from "@/components/octo-icon/OctoIcon";
import StudentSchedulePreferences from "@/pages/Students/components/StudentSchedulePreferences";
import LabelThemeComponent from "@/components/LabelThemeComponent";
import OctoHeaderButton from "@/components/octo-header-buttons/OctoHeaderButton";
import classroomTypes from "@/pages/Didactics/resources/classroomTypes";
import Modal from "@/components/Modal";
import Student from "@/models/student";
import OctoNotes from "@/components/OctoNotes";
import ClassroomPendingStudentData from "@/pages/Didactics/components/ClassroomPendingStudentData";
import Badge from "@/components/Badge";
import DealAccountingStatus from "@/pages/Deals/resources/dealAccountingStatus";
import {schedulerService} from "@/util/scheduler-service";

export default {
  name: "ClassroomPendingStudents",
  components: {
    Badge,
    ClassroomPendingStudentData,
    OctoNotes,
    Modal,
    OctoHeaderButton,
    LabelThemeComponent,
    StudentSchedulePreferences,
    OctoIcon,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Switch.name]: Switch,
  },
  data() {
    return {
      headerButtons: [
        {
          label: 'save_students',
          onClick: 'onSaveStudents'
        },
      ],
      studentsLocal: [],
      studentsAvailableLocal: [],
      studentsSelectedLocal: [],
      showStudentsWithScheduleOnly: false,
      showModal: false,
      studentKey: 0,
      currentStudent: this.$_.cloneDeep(Student),
      dealAccountingStatus: DealAccountingStatus,
      schedulerService: schedulerService,
    }
  },
  props: {},
  watch: {
    classroom: {
      handler(val) {
        const students = [];

        this.$_.each(this.$_.cloneDeep(this.classroom.students), student => {
          student.selected = true;
          students.push(student);
        });

        this.$_.each(this.$_.cloneDeep(this.classroom.availableStudents), student => {
          student.selected = false;
          students.push(student);
        });

        this.studentsLocal = this.$_.sortBy(students, student => {
          return student?.registry?.surname + ' ' + student?.registry?.name
        });
      },
      deep: true
    },
  },
  computed: {
    ...mapGetters({
      classroom: 'current/classroomPending',
      courses: 'common/allCourses'
    }),

    showAccountingApproval() {
      return this.$_.includes(
        [classroomTypes.certificate.value, classroomTypes.workshop.value],
        this.classroom?.courses[0]?.type
      );
    },

    studentsSelected() {
      return this.$_.filter(this.studentsLocal, student => student.selected)
    },

    studentsAvailable() {
      return this.$_.filter(this.studentsLocal, student => {
        return !student.selected && (student?.studentSchedulePreferences?.length || !this.showStudentsWithScheduleOnly);
      })
    },
  },
  methods: {
    ...mapMutations({
      setPartialClassroomPending: 'current/setPartialClassroomPending'
    }),

    showStudent(student) {
      this.currentStudent = this.$_.cloneDeep(student || Student);
      this.studentKey++;
      this.showModal = true;
    },

    updateStudentNotes(notes) {
      this.$set(
        this.studentsLocal[this.$_.findIndex(this.studentsLocal, student => student.id === this.currentStudent.id)],
        'notes',
        this.$_.cloneDeep(notes)
      )
    },

    toggleStudent(student) {
      this.$set(
        this.studentsLocal[this.$_.findIndex(this.studentsLocal, studentLocal => studentLocal.id === student.id)],
        'selected',
        !student.selected
      );
    },

    saveStudents: function () {
      this.$fullLoading.show();
      this.$api.put(
        endpoints.CLASSROOM_ON_BOARDING_STUDENTS.replace('{id}', this.classroom.id),
        {student_ids: this.$_.map(this.studentsSelected, (item) => item.id)}
      )
        .then((resp) => {
          this.setPartialClassroomPending({key: 'students', value: resp?.data?.data?.students});
          this.setPartialClassroomPending({key: 'availableStudents', value: resp?.data?.meta?.availableStudents});
          this.$notify({type: 'success', message: this.$t('notifications.saved_success')});
        })
        .catch(() => {
          this.$notify({type: 'danger', message: this.$t('notifications.loading_error')});
        })
        .finally(() => {
          this.$fullLoading.hide();
        })
    },

    getEnabledCertificationsCodes(student) {
      return this.$_.map(student?.registry?.enabled_certifications, item => this.courses[item.id].code).join(', ');
    },
  }
}
</script>

<style scoped>

</style>
