<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <octo-header-button
          :title="classroom.code"
          :buttons="headerButtons"
          @onSaveClassroom="saveClassroom"
          @onActivateClassroom="activateClassroom"
          @onToggleReadyStatus="toggleReadyStatus"
          @onDeleteClassroom="deleteClassroom"
          @onExportStudents="exportStudents"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <card body-classes="standard-card-body" class="shadow mb-0">
          <ul class="list-group list-group-flush">
            <list-group-item-component :label="$t('fields.code')" :label-width="labelWidth">
              <template slot="value">
                <base-input
                  class="flex-grow-1 mb-0"
                  v-model="localClassroom.code"
                  type="text"/>
              </template>
            </list-group-item-component>
            <list-group-item-component :label="$t('fields.schedulation')" :label-width="labelWidth">
              <template slot="value">
                <base-input
                  class="flex-grow-1 mb-0"
                  v-model="localClassroom.schedulation"
                  type="text"
                  :placeholder="$t('fields.schedulation')"
                />
              </template>
            </list-group-item-component>
            <list-group-item-component :label="$t('fields.expected_start_date')" :label-width="labelWidth">
              <template slot="value">
                <base-input class="flex-grow-1 mb-0">
                  <el-date-picker
                    type="date"
                    v-model="localClassroom.expected_start_date"
                    value-format="yyyy-MM-dd"
                    format="dd/MM/yyyy"
                    :placeholder="$t('common.select_day')"
                    :picker-options="{firstDayOfWeek: 1}"
                  />
                </base-input>
              </template>
            </list-group-item-component>
            <list-group-item-component :label="$t('fields.certificationBody')" :label-width="labelWidth">
              <template slot="value">
                <base-input
                  class="flex-grow-1 mb-0"
                  v-model="localClassroom.certification_body"
                  type="text"
                  :placeholder="$t('fields.certificationBody')"
                />
              </template>
            </list-group-item-component>
            <list-group-item-component :label="$t('fields.location')" :label-width="labelWidth">
              <template slot="value">
                <base-input class="mb-0 flex-grow-1">
                  <el-select
                    class="select-default text-uppercase"
                    filterable
                    clearable
                    value-key="id"
                    :placeholder="$t('common.select')"
                    v-model="localClassroom.location_id"
                  >
                    <el-option
                      class="select-default text-uppercase"
                      v-for="(location, index) in locations"
                      :value="location.id"
                      :label="location.alias"
                      :key="`location-${index}`"
                    />
                  </el-select>
                </base-input>
              </template>
            </list-group-item-component>
          </ul>
        </card>
      </div>
      <div class="col-md-6">
        <classroom-status-container :classroom="classroom"/>
        <card class="shadow" body-classes="standard-card-body pt-0">
          <ul class="list-group list-group-flush">
            <list-group-item-component
              :label="$t('didactics.joined_students')"
              :value="localClassroom.students.length"
            />
            <list-group-item-component
              :label="$t('didactics.available_students')"
              :value="localClassroom.availableStudents.length"
            />
            <list-group-item-component
              :label="$t('didactics.tutors_number')"
              :value="localClassroom.tutors.length"
            />
          </ul>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import OctoHeaderButton from "@/components/octo-header-buttons/OctoHeaderButton";
import {mapActions, mapGetters, mapMutations} from "vuex";
import ClassroomPending from "@/store/current/resources/classroomPending";
import ListGroupItemComponent from "@/components/ListGroupItemComponent";
import {DatePicker, Select, Option} from "element-ui";
import ClassroomStatusContainer from "@/pages/Didactics/components/ClassroomStatusContainer";
import {endpoints} from "@/routes/endpoints";
import LabelThemeComponent from "@/components/LabelThemeComponent";
import classroomStatuses from "@/pages/Didactics/resources/classroomStatuses";
import ClassroomPendingRecapCourses from "./ClassroomPendingRecapCourses";
import classroomTypes from "@/pages/Didactics/resources/classroomTypes";
import ClassroomPendingRecapCertificates from "./ClassroomPendingRecapCertificates";

export default {
  name: "ClassroomPendingRecap",
  components: {
    ClassroomPendingRecapCertificates,
    ClassroomPendingRecapCourses,
    LabelThemeComponent,
    ClassroomStatusContainer,
    ListGroupItemComponent,
    OctoHeaderButton,
    [DatePicker.name]: DatePicker,
    [Select.name]: Select,
    [Option.name]: Option,
  },
  data() {
    return {
      labelWidth: 160,
      localClassroom: this.$_.cloneDeep(ClassroomPending),
      endpoints: endpoints,
      classroomTypes: classroomTypes
    }
  },
  watch: {
    classroom: {
      handler(val) {
        this.localClassroom = this.$_.cloneDeep(this.classroom);
      },
      deep: true
    },
  },
  computed: {
    ...mapGetters({
      classroom: 'current/classroomPending',
      locations: 'common/locations',
    }),

    headerButtons() {
      const buttons = [
        {label: 'save', onClick: 'onSaveClassroom'},
        {
          label: 'eliminate',
          onClick: 'onDeleteClassroom',
          confirmRequired: true,
          confirmText: this.$t('confirm.delete_classroom')
        },
        {label: 'export_students', onClick: 'onExportStudents'},
      ];

      buttons.push({
          onClick: 'onToggleReadyStatus',
          label: this.classroom.status === classroomStatuses.ready_to_go.value
            ? 'not_ready_to_go'
            : 'ready_to_go',
        }
      );

      if (this.classroom.status === classroomStatuses.ready_to_go.value) {
        buttons.push({
            onClick: 'onActivateClassroom',
            label: 'activate_classroom',
          }
        );
      }

      return buttons;
    },
  },
  methods: {
    ...mapActions({
      downloadFile: 'common/downloadFile'
    }),

    ...mapMutations({
      setClassroomPending: 'current/setClassroomPending',
      setPartialClassroomPending: 'current/setPartialClassroomPending',
    }),

    saveClassroom: function () {

      this.$fullLoading.show();

      const data = {
        code: this.localClassroom.code,
        schedulation: this.localClassroom.schedulation,
        expected_start_date: this.localClassroom.expected_start_date,
        certification_body: this.localClassroom.certification_body,
        location_id: this.localClassroom.location_id
      };

      this.$api.put(endpoints.CLASSROOM_UPDATE_PENDING.replace('{id}', this.classroom.id), data)
        .then((resp) => {
          this.$_.each(data, (value, key) => {
            this.setPartialClassroomPending({key: key, value: resp?.data?.data?.[key]});
          });
          this.$notify({type: 'success', message: this.$t('notifications.saved_success')});
        })
        .catch((e) => {
          console.log(e);
          this.$notify({type: 'danger', message: this.$t('notifications.saved_error')});
        })
        .finally(() => {
          this.$fullLoading.hide();
        });
    },

    toggleReadyStatus: function () {
      this.$fullLoading.show();

      this.$api.put(endpoints.CLASSROOM_TOGGLE_READY_STATUS.replace('{id}', this.classroom.id))
        .then((resp) => {
          this.setPartialClassroomPending({key: 'status', value: resp?.data?.data?.status});
        })
        .catch(() => {
          this.$notify({type: 'danger', message: this.$t('notifications.saved_error')});
        })
        .finally(() => {
          this.$fullLoading.hide();
        })
    },

    deleteClassroom() {
      this.$fullLoading.show();

      this.$api.delete(endpoints.CLASSROOM_DELETE_PENDING.replace('{id}', this.classroom.id))
        .then(() => {
          this.setClassroomPending(this.$_.cloneDeep(ClassroomPending));
          this.$router.push({name: 'didactics.classrooms.pending.index'});
        })
        .catch(() => {
          this.$notify({type: 'danger', message: this.$t('notifications.delete_error')});
        })
        .finally(() => {
          this.$fullLoading.hide();
        })
    },

    activateClassroom: function () {
      this.$fullLoading.show();

      this.$api.put(endpoints.CLASSROOM_ACTIVATE.replace('{id}', this.classroom.id))
        .then(() => {
          this.$fullLoading.hide();
          this.$router.push({name: 'didactics.classrooms.on-going.show', params: {id: this.classroom.id}});
        })
        .catch(() => {
          this.$fullLoading.hide();
          this.$notify({type: 'danger', message: this.$t('notifications.saved_error')});
        })
    },

    exportStudents: function () {
      this.$fullLoading.show();

      this.downloadFile({
        url: endpoints.CLASSROOM_EXPORT_STUDENTS.replace('{id}', this.classroom.id),
        filename: 'classroom-' + this.classroom.code + '-students-export.xlsx',
        method: 'get',
      })
        .then()
        .catch(() => {
          this.$notify({type: 'danger', message: this.$t('notifications.export_error')});
        })
        .finally(() => {
          this.$fullLoading.hide();
        });
    },
  }
}
</script>

<style scoped>

</style>
